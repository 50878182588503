// firebase/config.js

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getFirestore, serverTimestamp } from "firebase/firestore"
import { getAuth } from "firebase/auth"

// Store API key from .env
const APIKEY = process.env.REACT_APP_PROD_API_KEY

// Your web app's Firebase configuration

// PRODUCTION KEY

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: APIKEY,
	authDomain: "comfindprod.firebaseapp.com",
	projectId: "comfindprod",
	storageBucket: "comfindprod.appspot.com",
	messagingSenderId: "787628408872",
	appId: "1:787628408872:web:10774d3bb7858dfac6b51d",
}

// Initialize Firebase
initializeApp(firebaseConfig)
const timestamp = serverTimestamp
const db = getFirestore()
const auth = getAuth()

// Export Database Key and API reference
export { db, timestamp, auth }
