// ./pages/Events/EventPage.js

import { Container, Typography, Paper, Grid } from "@mui/material"
import { useParams } from "react-router-dom"
import { Link } from "react-router-dom"
import { useDocument } from "../../hooks/useDocument"

const EventPage = () => {
	const params = useParams()
	const eventId = params.id

	const { document, error } = useDocument("events", eventId)

	if (error) {
		return <div className="error">{error}</div>
	}
	if (!document) {
		return <div className="loading">Loading...</div>
	}

	const {
		address,
		agerange,
		category,
		details,
		eventEnd,
		eventStart,
		imgUrl,
		title,
		venueName,
	} = document

	const eventDateinfo = eventStart.toDate()
	const eventDate = eventDateinfo.toDateString()
	const startTime = eventDateinfo.toLocaleTimeString()
	const endTime = eventEnd.toDate().toLocaleTimeString()

	return (
		<Container maxWidth="md" style={{ marginTop: "20px" }}>
			<Paper elevation={3} style={{ padding: "20px" }}>
				<Typography variant="h4" gutterBottom sx={{ color: "#009432" }}>
					{title}
				</Typography>
				<Typography variant="h6" gutterBottom>
					Venue: {venueName}
				</Typography>
				<Typography variant="body1" paragraph>
					{details}
				</Typography>
				<Typography variant="body1" paragraph>
					<Link to={imgUrl}>Event Web Site (More info): </Link>
				</Typography>
				<Grid container spacing={2} mb={4}>
					<Grid item xs={12} sm={6}>
						<Typography variant="body1">
							<strong>Event Date: </strong>
							{eventDate}
						</Typography>
						<Typography variant="body1">
							<strong>Address: </strong>
							{address}
						</Typography>
						<Typography variant="body1">
							<strong>Suggested Age Range: </strong>
							{agerange}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Typography variant="body1">
							<strong>Event Starts @: </strong>
							{startTime}
						</Typography>
						<Typography variant="body1">
							<strong>Event Ends - @: </strong>
							{endTime}
						</Typography>
						<Typography variant="body1">
							<strong>Event Type: </strong>
							{category}
						</Typography>
					</Grid>
				</Grid>
			</Paper>
		</Container>
	)
}

export default EventPage
