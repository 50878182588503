// Dashboard.js

// Standard React Hooks
import { useState, useEffect } from "react"

// Material UI for styling
import {
	Container,
	Typography,
	Paper,
	Button,
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from "@mui/material"
import Divider from "@mui/material/Divider"

// Link to Create Event using react-router-dom
import { Link, useNavigate } from "react-router-dom"

// Auth info from Firebase & useContext Hook
import { getAuth } from "firebase/auth"
import { useAuthContext } from "../../hooks/useAuthContext"
import {
	collection,
	deleteDoc,
	doc,
	getDocs,
	orderBy,
	query,
	where,
} from "firebase/firestore"
import { db } from "../../firebase/config"

// Toast for Messaging
import { toast } from "react-toastify"

// Our Custom Hooks & Components
import Spinner from "../../components/Spinner"

const Dashboard = () => {
	// Auth from Firebase for user management
	const auth = getAuth()
	// Our User Context management
	const { dispatch } = useAuthContext()

	// Redirect user to  homepage after log out.
	const navigate = useNavigate()
	// State for Logout
	const [isPending, setIsPending] = useState(false)
	const [events, setEvents] = useState()
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		async function fetchUserEvents() {
			const eventRef = collection(db, "events")
			const q = query(
				eventRef,
				where("userRef", "==", auth.currentUser.uid),
				orderBy("eventStart", "asc")
			)
			const querySnap = await getDocs(q)
			let events = []
			querySnap.forEach((doc) => {
				return events.push({
					id: doc.id,
					data: doc.data(),
				})
			})
			setEvents(events)
			setLoading(false)
		}
		fetchUserEvents()
	}, [auth.currentUser.uid])

	function onLogout() {
		setIsPending(true)
		auth.signOut()
		// dispatch logout action
		dispatch({ type: "LOGOUT" })
		navigate("/")
		toast.success("User logged out successfully.")
	}

	async function onDelete(eventID) {
		if (window.confirm("Are you sure you want to delete?")) {
			await deleteDoc(doc(db, "events", eventID))
			const updatedEvents = events.filter((event) => event.id !== eventID)
			setEvents(updatedEvents)
			toast.success("Successfully deleted the event")
		}
	}

	if (loading) {
		return (
			<>
				<Spinner message="Loading Events..." />
			</>
		)
	}

	return (
		<>
			<Container maxWidth="md" style={{ marginTop: "20px" }}>
				<Paper elevation={3} style={{ padding: "20px" }}>
					<Typography variant="h4" gutterBottom sx={{ color: "#009432" }}>
						Dashboard
					</Typography>
					<Typography variant="h6" gutterBottom>
						Hello {auth.currentUser.displayName}
					</Typography>

					<Typography variant="body1" paragraph>
						{!isPending && (
							<Button className="btn" onClick={onLogout}>
								Sign Out
							</Button>
						)}
						{isPending && (
							<Button className="btn" disabled>
								Logging out...
							</Button>
						)}
					</Typography>

					<Divider />
					<Typography variant="body1" paragraph>
						Events
					</Typography>

					<Link to="/events/create">
						<Button>+ Create Event</Button>
					</Link>

					<Typography variant="body1" paragraph>
						My Created Events
					</Typography>
					{!events && (
						<Typography variant="body1" paragraph>
							No Currently Created Events
						</Typography>
					)}

					{events && (
						<TableContainer component={Paper}>
							<Table
								sx={{ minWidth: 650 }}
								size="small"
								aria-label="a dense table"
							>
								<TableHead>
									<TableRow>
										<TableCell>DATE</TableCell>
										<TableCell align="right">TIME</TableCell>
										<TableCell align="right">TITLE</TableCell>
										<TableCell align="right">VENUE</TableCell>
										<TableCell align="right">VIEW</TableCell>
										<TableCell align="right">EDIT</TableCell>
										<TableCell align="right">DELETE</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{events.map((event) => (
										<TableRow
											key={event.id}
											sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
										>
											<TableCell component="th" scope="row">
												{event.data.eventStart.toDate().toDateString()}
											</TableCell>
											<TableCell align="right">
												{event.data.eventStart.toDate().toLocaleTimeString()}
											</TableCell>
											<TableCell align="right">{event.data.title}</TableCell>
											<TableCell align="right">
												{event.data.venueName}
											</TableCell>
											<TableCell align="right">
												<Link to={`/events/${event.id}`}>Info</Link>
											</TableCell>
											<TableCell align="right">
												<Link to={`/events/edit/${event.id}`}>Edit</Link>
											</TableCell>
											<TableCell align="right">
												<Link onClick={() => onDelete(event.id)}>Delete</Link>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</Paper>
			</Container>
		</>
	)
}

export default Dashboard
