// React hooks
import { useState } from "react"
import { useNavigate } from "react-router-dom"

// Material UI for Styling
import {
	Grid,
	Paper,
	Avatar,
	TextField,
	Button,
	Typography,
	Link,
} from "@mui/material"

// Add on Icons for Material UI (Google & Apple)
// import { FcGoogle } from "react-icons/fc"
// import { FaAppleAlt } from "react-icons/fa"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"

// Firebase hooks for login
import { signInWithEmailAndPassword, getAuth } from "firebase/auth"

// Google Login Component
import OAuth from "../../components/OAuth"

// Update Global Context to allow other components to know if user is logged in.
import { useAuthContext } from "../../hooks/useAuthContext"

// Toast for messaging
import { toast } from "react-toastify"

const Login = () => {
	// State variable for form inputs
	const [formData, setFormData] = useState({ email: "", password: "" })
	const { email, password } = formData

	// Dispatch to context after logged in
	const { dispatch } = useAuthContext()

	// Init navigate function for user redirect after input
	const navigate = useNavigate()

	// MaterialUI constants
	const paperStyle = {
		padding: 20,
		height: "70vh",
		width: 280,
		margin: "20px auto",
	}
	const TextFieldStyle = {
		marginBottom: "1em",
		marginTop: "1em",
	}
	const avatarStyle = { backgroundColor: "#93cb52" }
	const btnstyle = { margin: "8px 0", color: "white", backgroundColor: "black" }

	// Form Data Handlers
	function onChange(e) {
		setFormData((prevState) => ({
			...prevState,
			[e.target.id]: e.target.value,
		}))
	}

	// Submit form

	async function onSubmit(e) {
		e.preventDefault()
		try {
			const auth = getAuth()
			const userCredential = await signInWithEmailAndPassword(
				auth,
				email,
				password
			)
			if (userCredential.user) {
				navigate("/dashboard")
				// dispatch login action
				dispatch({ type: "LOGIN", payload: userCredential.user })
			}
		} catch (error) {
			toast.error("Bad user credentials")
		}
	}

	return (
		<Grid>
			<Paper elevation={10} style={paperStyle}>
				<Grid align="center">
					<Avatar style={avatarStyle}>
						<LockOutlinedIcon />
					</Avatar>
					<h2>Log In</h2>
				</Grid>

				<OAuth />

				<Typography style={{ marginBottom: "1em" }}>
					New to Community Find?
					<Link href="/signup" style={{ paddingLeft: ".5em" }}>
						Sign Up
					</Link>
				</Typography>

				<TextField
					label="Email"
					placeholder="Email"
					value={email}
					id="email"
					onChange={onChange}
					fullWidth
					required
				/>

				<TextField
					label="Password"
					placeholder="Enter password"
					type="password"
					value={password}
					id="password"
					onChange={onChange}
					fullWidth
					required
					style={TextFieldStyle}
				/>

				<Typography style={{ marginBottom: "1em" }}>
					<Link href="/forgotpass">Forgot password ?</Link>
				</Typography>

				<Button
					variant="contained"
					style={(btnstyle, avatarStyle)}
					fullWidth
					onClick={onSubmit}
				>
					Log in
				</Button>
			</Paper>
		</Grid>
	)
}

export default Login
