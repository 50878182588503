// pages/Home/Home.js

// Standard React Hooks

import { useState, useEffect, useMemo } from "react"

// Connect to Firebase Database and retrieve events
import { useCollection } from "../../hooks/useCollection"

// Toast for messaging
import { toast } from "react-toastify"

// Our Custom Hooks & Components
import { selectPin } from "../../components/PinHelper"
import EventCard from "../../components/EventCard"
import Spinner from "../../components/Spinner"

// Location context for keeping track of user center on map
import { useLocation } from "../../context/UserLocationContext"

// Mapbox hooks
import Map, {
	Popup,
	Marker,
	NavigationControl,
	ScaleControl,
	GeolocateControl,
} from "react-map-gl"

// Mapbox API access token
const TOKEN = process.env.REACT_APP_MAPBOX_TOKEN

export default function Home() {
	const { location, setLocation } = useLocation() // Use the context
	const [popupInfo, setPopupInfo] = useState(null)
	const [viewState, setViewState] = useState({
		latitude: 0,
		longitude: 0,
		zoom: 11,
	})

	//  Load events
	const { documents, error, loading } = useCollection("events")

	useEffect(() => {
		const fetchLocation = async () => {
			if (location) {
				// If location is already in context, use it
				setViewState((prevState) => ({
					...prevState,
					latitude: location.latitude,
					longitude: location.longitude,
				}))
				return
			}

			try {
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(success, error)
				} else {
					toast.error("Geolocation not supported/enabled")
					setViewState({ latitude: 39.8283, longitude: -98.5795, zoom: 7 })
				}
			} catch (error) {
				console.error("Error fetching location:", error.message)
				setViewState({ latitude: 39.8283, longitude: -98.5795, zoom: 7 })
			}

			function success(position) {
				const userLocation = {
					latitude: position.coords.latitude,
					longitude: position.coords.longitude,
				}
				setViewState({
					latitude: userLocation.latitude,
					longitude: userLocation.longitude,
					zoom: 11,
				})
				setLocation(userLocation) // Store location in context
			}

			function error() {
				setViewState({ latitude: 39.8283, longitude: -98.5795, zoom: 7 })
				toast.error("Unable to retrieve your location")
			}
		}

		fetchLocation()
	}, [location, setLocation])

	const pins = useMemo(() => {
		if (!documents) return null // Do not render anything if documents are not loaded yet
		return documents.map((event) => (
			<Marker
				key={`marker-${event.id}`}
				longitude={event.geolocation.lng}
				latitude={event.geolocation.lat}
				anchor="bottom"
				onClick={(e) => {
					e.originalEvent.stopPropagation()
					setPopupInfo(event)
				}}
			>
				{selectPin(event.category)}
			</Marker>
		))
	}, [documents])

	const onRecenter = (evt) => {
		setViewState(evt.viewState)
		setLocation(evt.viewState)
	}

	// Wait for browser to give us user location or default
	if (!location && !viewState.latitude) {
		return (
			<>
				<Spinner message="Searching for your location..." />
			</>
		)
	}

	if (loading) {
		return (
			<>
				<Spinner message="Loading Events" />
			</>
		)
	}

	if (error) {
		return (
			<>
				<div>There was an error downloading event data. Are you offline?</div>
			</>
		)
	}
	return (
		documents && (
			<div>
				<Map
					{...viewState}
					mapStyle="mapbox://styles/mapbox/streets-v11"
					mapboxAccessToken={TOKEN}
					style={{ width: "100vw", height: "100vh" }}
					onMove={(evt) => onRecenter(evt)}
				>
					<GeolocateControl position="top-left" />
					<NavigationControl position="top-left" />
					<ScaleControl />

					{pins}
					{popupInfo && (
						<Popup
							anchor="top"
							maxWidth="400px"
							longitude={Number(popupInfo.geolocation.lng)}
							latitude={Number(popupInfo.geolocation.lat)}
							onClose={() => setPopupInfo(null)}
						>
							<EventCard event={popupInfo} />
						</Popup>
					)}
				</Map>
			</div>
		)
	)
}
