// SignUp.js - New Signup Form

// Normal React Hooks
import { useState } from "react"

import {
	Grid,
	Paper,
	Avatar,
	Typography,
	TextField,
	Button,
	Link,
	Divider,
} from "@mui/material"
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"

// Firebase imports
import {
	getAuth,
	createUserWithEmailAndPassword,
	updateProfile,
	sendEmailVerification,
} from "firebase/auth"
import { db } from "../../firebase/config"
import { doc, serverTimestamp, setDoc } from "firebase/firestore"

// Navigation (Direct user to other page when form complete)
import { useNavigate } from "react-router-dom"

// Toastify for messaging (Error & Success)
import { toast } from "react-toastify"

// Google Login Component
import OAuth from "../../components/OAuth"

// Auth Context for USer Management
import { useAuthContext } from "../../hooks/useAuthContext"

function SignUp() {
	// State for form inputs
	const [formData, setFormData] = useState({
		username: "",
		email: "",
		password: "",
	})
	const { username, email, password } = formData

	// Our User Context management
	const { dispatch } = useAuthContext()

	// Enable navigate to redirect user after signup.
	const navigate = useNavigate()

	// Material UI CSS RULES
	const paperStyle = { padding: "30px 20px", width: 300, margin: "20px auto" }
	const headerStyle = { marginTop: ".5em" }
	const avatarStyle = { backgroundColor: "#93cb52" }
	const marginTop = { marginTop: "1em" }
	const btnstyle = { margin: "8px 0", marginTop: "1em" }

	// HANDLERS

	// Manage form data
	function onChange(e) {
		setFormData((prevState) => ({
			...prevState,
			[e.target.id]: e.target.value,
		}))
	}

	// Submit Form, Create User and Redirect to Home page

	async function onSubmit(e) {
		e.preventDefault()

		try {
			const auth = getAuth()
			const userCredential = await createUserWithEmailAndPassword(
				auth,
				email,
				password
			)
			// send verification email
			await sendEmailVerification(userCredential.user)

			updateProfile(auth.currentUser, {
				displayName: username,
			})

			const user = userCredential.user
			const formDataCopy = { ...formData }
			delete formDataCopy.password
			formDataCopy.timestamp = serverTimestamp()

			await setDoc(doc(db, "users", user.uid), formDataCopy)
			toast.success(
				"Email verification sent. Please verify email before logging in."
			)
			// dispatch logout action
			dispatch({ type: "LOGOUT" })

			navigate("/")
		} catch (error) {
			toast.error("Something went wrong with the registration")
		}
	}

	return (
		<Grid>
			<Paper elevation={20} style={paperStyle}>
				<Grid align="center">
					<Avatar style={avatarStyle}>
						<AddCircleOutlineOutlinedIcon />
					</Avatar>
					<h2 style={headerStyle}>Create Account</h2>
				</Grid>

				<OAuth />

				<Grid align="center">
					<Divider variant="middle" style={{ marginBottom: "1em" }} />
				</Grid>

				<form>
					<TextField
						fullWidth
						label="Username"
						placeholder="Choose Username"
						id="username"
						value={username}
						onChange={onChange}
					/>
					<TextField
						fullWidth
						style={marginTop}
						label="Email"
						placeholder="Enter your email"
						id="email"
						value={email}
						onChange={onChange}
					/>
					<TextField
						fullWidth
						style={marginTop}
						label="Password"
						placeholder="Create a password"
						type="password"
						id="password"
						value={password}
						onChange={onChange}
					/>

					<Button
						variant="contained"
						style={btnstyle}
						fullWidth
						color="primary"
						onClick={onSubmit}
					>
						Create Account
					</Button>
				</form>

				<Typography style={{ paddingTop: "1em" }}>
					Already have an account ?
					<Link href="/login" style={{ paddingLeft: ".5em" }}>
						Log In
					</Link>
				</Typography>
			</Paper>
		</Grid>
	)
}

export default SignUp
