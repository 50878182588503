// components/EventCard.js.
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import ButtonBase from "@mui/material/ButtonBase"
import { Link } from "react-router-dom"
import DateIcon from "../components/DateIcon"
import "./DateIcon.css"

export default function EventCard({ event }) {
	return (
		<Paper
			sx={{
				p: 2,
				margin: "auto",
				maxWidth: 500,
				flexGrow: 1,
				backgroundColor: (theme) =>
					theme.palette.mode === "dark" ? "#1A2027" : "#fff",
			}}
		>
			<Grid container spacing={2}>
				<Grid item>
					<ButtonBase sx={{ width: 80, height: 80 }}>
						<div className="dateIcon">
							<DateIcon eventDate={event.eventStart} />
						</div>
					</ButtonBase>
				</Grid>
				<Grid item xs={12} sm container>
					<Grid item xs container direction="column" spacing={2}>
						<Grid item xs>
							<Typography
								gutterBottom
								component="div"
								variant="h6"
								sx={{ color: "#009432" }}
							>
								{event.title}
							</Typography>
							<Typography variant="body2" gutterBottom>
								<strong>Address: </strong>
								{event.address}
							</Typography>
							<Typography variant="body2" color="text.secondary">
								<strong>Event Starts @: </strong>
								{event.eventStart.toDate().toLocaleTimeString()}
							</Typography>
						</Grid>
						<Grid item>
							<Typography sx={{ cursor: "pointer" }} variant="body2">
								<Link to={`/events/${event.id}`}>Event Details...</Link>
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	)
}
