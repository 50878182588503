// Material UI for Styling

import { Grid, Typography } from "@mui/material"
import spinner from "../assets/svg/spinner.svg"

function Spinner({ message }) {
	return (
		<Grid container direction="column" alignItems="center" sx={{ p: 2 }}>
			<img src={spinner} alt="Loading..." />
			{message && (
				<Typography variant="h4" sx={{ mt: 2 }}>
					{message}
				</Typography>
			)}
		</Grid>
	)
}

export default Spinner
