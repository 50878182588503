// Age Ranges
export const ageranges = [
	{ value: "all", label: "All Ages" },
	{ value: "kids", label: "Kids" },
	{ value: "teen", label: "Teen" },
	{ value: "18+", label: "18 And Older" },
	{ value: "21+", label: "21 and Older" },
	{ value: "senior", label: "Senior Citizens" },
	{ value: "other", label: "Other" },
]
// Event Type Categories
export const categories = [
	{ value: "Attractions", label: "Attraction (Exhibits, Experiences, Etc.)" },
	{ value: "Concerts", label: "Concerts (Musical Performance)" },
	{
		value: "Conferences",
		label: "Conference (Conferences & Conventions, Etc.)",
	},
	{ value: "Expos", label: "Exposition (Art or Trade Show)" },
	{ value: "Festivals", label: "Festival (Festivals, Fairs, Etc.)" },
	{ value: "Fundraisers", label: "Fundraiser (Charity Event)" },
	{ value: "Gatherings", label: "Gatherings (Social, Dinners, Galas, Etc.)" },
	{ value: "Parades", label: "Parade" },
	{ value: "Performances", label: "Performance (Live, Theatre, Etc.)" },
	{ value: "Premieres", label: "Premiere (Movie, Opening, Etc.)" },
	{ value: "Sales", label: "Sale (Clearance, Farmer's Market, Etc.)" },
	{ value: "Shows", label: "Show (Fashion, Comedy, Magic, Etc.)" },
	{ value: "Sports", label: "Sporting Events (Pro and Amateur)" },
	{ value: "Tastings", label: "Tasting (Food and Drink)" },
	{ value: "Workshops", label: "Workshop (Training, Class, Etc.)" },
	{ value: "Other", label: "Other" },
]
