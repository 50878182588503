// Standard React Hooks
import React from "react"
import ReactDOM from "react-dom/client"

// CSS Styling
import "./index.css"

// Import Main App Component
import App from "./App"

// Auth Context for User Management
import { AuthContextProvider } from "./context/AuthContext"

// Material UI Theme Context
import { ThemeProvider } from "@mui/material/styles"
import theme from "./theme"

// User Location Context - Keep track of where user was centered on the map so we can bring them back there
import { LocationProvider } from "./context/UserLocationContext"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<LocationProvider>
				<AuthContextProvider>
					<App />
				</AuthContextProvider>
			</LocationProvider>
		</ThemeProvider>
	</React.StrictMode>
)
