import { useEffect, useState } from "react"
import { getFirestore, doc, onSnapshot } from "firebase/firestore"

export const useDocument = (collection, id) => {
	const [document, setDocument] = useState(null)
	const [error, setError] = useState(null)

	useEffect(() => {
		const firestore = getFirestore()
		const docRef = doc(firestore, collection, id)

		const unsubscribe = onSnapshot(
			docRef,
			(snapshot) => {
				// need to make sure the doc exists & has data
				if (snapshot.exists()) {
					setDocument({ ...snapshot.data(), id: snapshot.id })
					setError(null)
				} else {
					setError("No such document exists")
				}
			},
			(err) => {
				setError("failed to get document")
			}
		)

		// unsubscribe on unmount
		return () => unsubscribe()
	}, [collection, id])

	return { document, error }
}
