import { useEffect, useState, useRef } from "react"
import {
	collection,
	query as firestoreQuery,
	where,
	orderBy as firestoreOrderBy,
	onSnapshot,
} from "firebase/firestore"

import { db } from "../firebase/config"

export const useCollection = (collectionName, _query, _orderBy) => {
	const [documents, setDocuments] = useState(null)
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)

	// if we don't use a ref --> infinite loop in useEffect
	// _query is an array and is "different" on every function call
	const query = useRef(_query).current
	const orderBy = useRef(_orderBy).current

	useEffect(() => {
		setLoading(true)
		let ref = collection(db, collectionName)

		if (query) {
			ref = firestoreQuery(ref, where(...query))
		}
		if (orderBy) {
			ref = firestoreQuery(ref, firestoreOrderBy(...orderBy))
		}

		const unsubscribe = onSnapshot(
			ref,
			(snapshot) => {
				let results = []
				snapshot.docs.forEach((doc) => {
					results.push({ ...doc.data(), id: doc.id })
				})

				// update state
				setDocuments(results)
				setError(null)
				setLoading(false)
			},
			(error) => {
				setError("could not fetch the data")
			}
		)

		// unsubscribe on unmount
		return () => unsubscribe()
	}, [collectionName, query, orderBy])

	return { documents, error, loading }
}
