/* // components/DateIcon.js */

function DateIcon({ eventDate }) {
	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	]

	let month = eventDate.toDate().getMonth()
	let day = eventDate.toDate().getDate()

	let text = months[month]
	let dir = month + 1
	if (dir < 10) dir = "0" + dir

	let imgsrc =
		"/assets/Date_Icons/" + dir + "_" + text + "_Dates/" + day + ".png"

	return (
		<>
			<img
				className="dateIcon"
				src={imgsrc}
				alt={eventDate.toDate().toDateString()}
			></img>
		</>
	)
}

export default DateIcon
