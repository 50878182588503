import React, { createContext, useState, useContext } from "react"

// Create a context for the user's location
const LocationContext = createContext()

export const useLocation = () => useContext(LocationContext)

export const LocationProvider = ({ children }) => {
	const [location, setLocation] = useState(null)

	return (
		<LocationContext.Provider value={{ location, setLocation }}>
			{children}
		</LocationContext.Provider>
	)
}
