// components/PinHelper.js

import "./PinStyle.css"

import Attractions from "../assets/Pins/Attractions.svg"
import Concerts from "../assets/Pins/Concerts.svg"
import Conferences from "../assets/Pins/Conferences.svg"
import Expos from "../assets/Pins/Expos.svg"
import Festivals from "../assets/Pins/Festivals.svg"
import Fundraisers from "../assets/Pins/Fundraisers.svg"
import Gatherings from "../assets/Pins/Gatherings.svg"
import Other from "../assets/Pins/Other.svg"
import Parades from "../assets/Pins/Parades.svg"
import Performances from "../assets/Pins/Performances.svg"
import Premieres from "../assets/Pins/Premieres.svg"
import Sales from "../assets/Pins/Sales.svg"
import Shows from "../assets/Pins/Shows.svg"
import Sports from "../assets/Pins/Sports.svg"
import Tastings from "../assets/Pins/Tastings.svg"
import Workshops from "../assets/Pins/Workshops.svg"

export const pinImages = {
	Attractions,
	Concerts,
	Conferences,
	Expos,
	Festivals,
	Fundraisers,
	Gatherings,
	Other,
	Parades,
	Performances,
	Premieres,
	Sales,
	Shows,
	Sports,
	Tastings,
	Workshops,
}

export const selectPin = (category) => {
	const pinImage = pinImages[category] || pinImages.Other
	return <img className="pin" src={pinImage} alt={category} />
}
