// components/NavBar.js

import { useState } from "react"
import { useAuthContext } from "../hooks/useAuthContext"

import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Menu from "@mui/material/Menu"
import MenuIcon from "@mui/icons-material/Menu"
import Container from "@mui/material/Container"
import Avatar from "@mui/material/Avatar"
import Button from "@mui/material/Button"
import { MenuItem } from "@mui/material"
import { Link } from "react-router-dom"

const activeItems = [
	{ name: "Event Map", link: "/" },
	{ name: "Dashboard", link: "/dashboard" },
]
const notLiItems = [
	{ name: "Event Map", link: "/" },
	{ name: "Login/Signup", link: "/login" },
]

function NavBar() {
	const [anchorElNav, setAnchorElNav] = useState(null)
	const { user } = useAuthContext()

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget)
	}

	const handleCloseNavMenu = () => {
		setAnchorElNav(null)
	}

	return (
		<AppBar position="static" sx={{ backgroundColor: "black" }}>
			<Container maxWidth="xl">
				<Toolbar disableGutters>
					<Typography
						variant="h6"
						noWrap
						component="a"
						href="/"
						sx={{
							mr: 2,
							display: { xs: "none", md: "flex" },
							fontFamily: "roboto",
							fontWeight: 700,
							letterSpacing: ".1rem",
							color: "#93cb52",
							textDecoration: "none",
						}}
					>
						Community Find
					</Typography>

					<Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleOpenNavMenu}
							color="primary"
						>
							<MenuIcon />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "left",
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: "block", md: "none" },
							}}
						>
							{user &&
								activeItems.map((item) => (
									<MenuItem
										component={Link}
										to={item.link}
										key={item.name}
										onClick={handleCloseNavMenu}
									>
										<Typography textAlign="center">{item.name}</Typography>
									</MenuItem>
								))}

							{!user &&
								notLiItems.map((item) => (
									<MenuItem
										component={Link}
										to={item.link}
										key={item.name}
										onClick={handleCloseNavMenu}
									>
										<Typography textAlign="center">{item.name}</Typography>
									</MenuItem>
								))}
						</Menu>
					</Box>
					<Typography
						variant="h5"
						noWrap
						component="a"
						href="#app-bar-with-responsive-menu"
						sx={{
							mr: 2,
							display: { xs: "flex", md: "none" },
							flexGrow: 1,
							fontFamily: "monospace",
							fontWeight: 700,
							letterSpacing: ".3rem",
							color: "inherit",
							textDecoration: "none",
						}}
					></Typography>
					{/* This is the larger menu on navbar and not the hamburger drop down */}
					<Box
						sx={{
							flexGrow: 1,
							display: { xs: "none", md: "flex" },
							justifyContent: "flex-end",
						}}
					>
						{user &&
							activeItems.map((page) => (
								<Button
									component={Link}
									to={page.link}
									key={page.name}
									onClick={handleCloseNavMenu}
									sx={{ my: 2, color: "white", display: "block" }}
								>
									{page.name}
								</Button>
							))}

						{!user &&
							notLiItems.map((page) => (
								<Button
									component={Link}
									to={page.link}
									key={page.name}
									onClick={handleCloseNavMenu}
									sx={{ my: 2, color: "white", display: "block" }}
								>
									{page.name}
								</Button>
							))}
					</Box>

					<Box sx={{ flexGrow: 0 }}>
						{user && (
							<IconButton sx={{ p: 0 }}>
								<Avatar alt="LI" src="/assets/logos/CFMainMapLogo.svg" />
							</IconButton>
						)}
						{!user && (
							<IconButton sx={{ p: 0 }}>
								<Avatar alt="CF" src="/assets/logos/CFMainMapLogo.svg" />
							</IconButton>
						)}
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	)
}
export default NavBar
