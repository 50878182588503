// Standard React Hooks
import { useState, useRef, useEffect, useCallback } from "react"
// Make sure user is logged in.
import { useAuthContext } from "../../hooks/useAuthContext"
// Get the users location from context
import { useLocation } from "../../context/UserLocationContext"
// Bring user back to Dashboard after event has been created
import { useNavigate } from "react-router-dom"

// Material UI COmponents
import {
	Container,
	Typography,
	Paper,
	Grid,
	TextField,
	Button,
	TextareaAutosize,
	Stack,
} from "@mui/material"

// Material UI X-Date And Time Pickers
import { DemoItem } from "@mui/x-date-pickers/internals/demo"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import dayjs from "dayjs"

// Mapbox
import mapboxgl from "mapbox-gl"
import { AddressAutofill } from "@mapbox/search-js-react"

// Firebase
import { collection, addDoc, Timestamp } from "firebase/firestore"
import { db } from "../../firebase/config"

// Error and Success Messaging
import { toast } from "react-toastify"

// Select Helper
import Select from "react-select"

// Our Custom Hooks & Components
import Spinner from "../../components/Spinner"

// Global Variables
import { categories, ageranges } from "./EventVars"
// Pins
import AttractionsPin from "../../assets/Pins/Attractions.svg"
import ConcertsPin from "../../assets/Pins/Concerts.svg"
import ConferencesPin from "../../assets/Pins/Conferences.svg"
import ExposPin from "../../assets/Pins/Expos.svg"
import FestivalsPin from "../../assets/Pins/Festivals.svg"
import FundraisersPin from "../../assets/Pins/Fundraisers.svg"
import GatheringsPin from "../../assets/Pins/Gatherings.svg"
import ParadesPin from "../../assets/Pins/Parades.svg"
import PerformancesPin from "../../assets/Pins/Performances.svg"
import PremieresPin from "../../assets/Pins/Premieres.svg"
import SalesPin from "../../assets/Pins/Sales.svg"
import ShowsPin from "../../assets/Pins/Shows.svg"
import SportsPin from "../../assets/Pins/Sports.svg"
import TastingsPin from "../../assets/Pins/Tastings.svg"
import WorkshopsPin from "../../assets/Pins/Workshops.svg"
import OtherPin from "../../assets/Pins/Other.svg"

const categoryMarkers = {
	Attractions: AttractionsPin,
	Concerts: ConcertsPin,
	Conferences: ConferencesPin,
	Expos: ExposPin,
	Festivals: FestivalsPin,
	Fundraisers: FundraisersPin,
	Gatherings: GatheringsPin,
	Parades: ParadesPin,
	Performances: PerformancesPin,
	Premieres: PremieresPin,
	Sales: SalesPin,
	Shows: ShowsPin,
	Sports: SportsPin,
	Tastings: TastingsPin,
	Workshops: WorkshopsPin,
	Other: OtherPin,
}

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN
mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN

export default function CreateEvent() {
	// State Variables
	const [startDate, setStartDate] = useState(dayjs())
	const [startTime, setStartTime] = useState(dayjs())
	const [endTime, setEndTime] = useState(dayjs())
	const [loading, setLoading] = useState(true)
	const [formError, setFormError] = useState(null)
	const [formData, setFormData] = useState({
		address: "",
		streetAddress: "",
		city: "",
		state: "",
		zip: "",
		agerange: null,
		category: null,
		details: "",
		geolocation: { lat: 38.7946, lng: -106.5348 },
		imgUrl: "",
		title: "",
		venueName: "",
	})
	const {
		streetAddress,
		city,
		state,
		zip,
		agerange,
		category,
		details,
		imgUrl,
		title,
		venueName,
	} = formData

	// Ref Variables for Map
	const mapContainerRef = useRef(null)
	const mapRef = useRef(null)
	const markerRef = useRef(null)
	const geolocationRef = useRef({ lat: 38.7946, lng: -106.5348 }) // Geolocation as
	// Fetch the location from context
	const { location, setLocation } = useLocation() // Use the context

	// State for Map Style
	const [mapStyle, setMapStyle] = useState("mapbox://styles/mapbox/streets-v11")

	// Navigate to Dashboard after completion of form
	const navigate = useNavigate()

	// Ensure user is logged in
	const { user } = useAuthContext()

	// USE_EFFECTS ON LOADING to run before rendering form

	// #1 Find User's location or use default
	useEffect(() => {
		const fetchLocation = async () => {
			if (location) {
				// If location is already in context, use it
				geolocationRef.current = {
					lat: location.latitude,
					lng: location.longitude,
				}
				return
			}

			try {
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(success, error)
				} else {
					toast.error("Geolocation not supported/enabled")
					geolocationRef.current = { lat: 38.7946, lng: -106.5348 }
				}
			} catch (error) {
				console.error("Error fetching location:", error.message)
				geolocationRef.current = { lat: 38.7946, lng: -106.5348 }
			}

			function success(position) {
				const userLocation = {
					latitude: position.coords.latitude,
					longitude: position.coords.longitude,
				}
				geolocationRef.current = {
					lat: userLocation.latitude,
					lng: userLocation.longitude,
				}
				setLocation(userLocation) // Store location in context
			}

			function error() {
				geolocationRef.current = { lat: 38.7946, lng: -106.5348 }
				toast.error("Unable to retrieve your location. Using default location.")
			}
		}

		fetchLocation()
	}, [location, setLocation])

	// #2 - Add map to form centered on users location or default location
	useEffect(() => {
		if (!mapContainerRef.current || !geolocationRef.current) return // If location is not available, don't run the map initialization

		// Initialize Mapbox
		mapRef.current = new mapboxgl.Map({
			container: mapContainerRef.current,
			style: mapStyle, // Use the dynamic map style
			center: [geolocationRef.current.lng, geolocationRef.current.lat], // Use geolocationRef
			zoom: 10,
		})

		// Add GeolocateControl and NavigationControl
		mapRef.current.addControl(
			new mapboxgl.GeolocateControl({ trackUserLocation: true }),
			"top-left"
		)
		mapRef.current.addControl(new mapboxgl.NavigationControl(), "top-left")

		// Add a draggable marker to the map
		markerRef.current = new mapboxgl.Marker({
			draggable: true,
		})
			.setLngLat([geolocationRef.current.lng, geolocationRef.current.lat]) // Use geolocationRef
			.addTo(mapRef.current)

		// Update formData when the marker is dragged
		markerRef.current.on("dragend", () => {
			const lngLat = markerRef.current.getLngLat()
			geolocationRef.current = { lat: lngLat.lat, lng: lngLat.lng } // Update the ref without triggering a re-render
		})

		setLoading(false)
		return () => {
			if (mapRef.current) {
				mapRef.current.remove() // Clean up map
			}
		}
	}, [location, mapStyle])

	// Customize the pin according to the event Type
	//
	// Function to create a custom marker element
	const createCustomMarker = useCallback((imageSrc) => {
		const el = document.createElement("div")
		el.style.backgroundImage = `url(${imageSrc})`
		el.style.width = "50px"
		el.style.height = "50px"
		el.style.backgroundSize = "contain"
		el.style.backgroundRepeat = "no-repeat"
		return el
	}, [])

	// Utility function to combine date and time
	const combineDateAndTime = (date, time) => {
		const combined = dayjs(date)
			.set("hour", dayjs(time).hour())
			.set("minute", dayjs(time).minute())
			.set("second", 0) // Set seconds to 0 for consistency
		return combined
	}

	// Form Change Handlers.

	// Category Handler - Change the displayed Marker to corresponding Pin
	const handleCategoryChange = (selectedOption) => {
		setFormData((prevState) => ({
			...prevState,
			category: selectedOption,
		}))

		if (markerRef.current) {
			markerRef.current.remove()
		}

		const newMarkerImage = categoryMarkers[selectedOption.value]

		markerRef.current = new mapboxgl.Marker({
			element: createCustomMarker(newMarkerImage), // Use the imported marker image
			draggable: true,
		})
			.setLngLat([geolocationRef.current.lng, geolocationRef.current.lat]) // Use geolocationRef
			.addTo(mapRef.current)

		markerRef.current.on("dragend", () => {
			const lngLat = markerRef.current.getLngLat()
			geolocationRef.current = { lat: lngLat.lat, lng: lngLat.lng } // Update the ref
		})
	}

	// Handle style switch between street view and satellite
	const toggleMapStyle = () => {
		if (mapStyle === "mapbox://styles/mapbox/streets-v11") {
			setMapStyle("mapbox://styles/mapbox/satellite-v9")
		} else {
			setMapStyle("mapbox://styles/mapbox/streets-v11")
		}
	}

	// Select needs it's own handler
	const onSelectChange = (selectedOption, field) => {
		setFormData((prevState) => ({
			...prevState,
			[field]: selectedOption,
		}))
	}

	// Everything else except lat and long.
	const onChange = (e) => {
		setFormData((prevState) => ({
			...prevState,
			[e.target.id]: e.target.value,
		}))
	}

	const handleAutofillRetrieve = (response) => {
		const { place_name, geometry } = response.features[0]
		const [lng, lat] = geometry.coordinates
		const { address_line1, address_level2, address_level1, postcode } =
			response.features[0].properties
		const fullAddress =
			address_line1 +
			", " +
			address_level2 +
			", " +
			address_level1 +
			", " +
			postcode

		setFormData((prevState) => ({
			...prevState,
			address: fullAddress,
			streetAddress: place_name,
			city: address_level2,
			state: address_level1,
			zip: postcode,
			geolocation: { lat, lng },
		}))

		// Move marker to the new location
		markerRef.current.setLngLat([lng, lat])
		mapRef.current.flyTo({ center: [lng, lat], essential: true })
	}

	// One last check if all is well and then submit

	const handleFormSubmit = useCallback(
		async (e) => {
			e.preventDefault()
			setLoading(true)

			// Everything is checked via Material UI "required" except the selects

			if (!formData.category) {
				toast.error("Please select an event category")
				setLoading(false)
				return
			}

			if (!formData.agerange) {
				toast.error("Please choose a suggested age range.")
				setLoading(false)
				return
			}

			// Combine the date and time for event start and end
			const combinedStartDate = combineDateAndTime(startDate, startTime)
			const combinedEndDate = combineDateAndTime(startDate, endTime) // Keep same date for now or modify

			try {
				const formDataCopy = {
					...formData,
					userRef: user.uid,
					eventStart: Timestamp.fromDate(combinedStartDate.toDate()), // Save combined start date and time
					eventEnd: Timestamp.fromDate(combinedEndDate.toDate()), // Save combined end date and time
					agerange: formData.agerange.value,
					category: formData.category.value,
					geolocation: geolocationRef.current, // Use the current location from the ref
				}

				const eventRef = collection(db, "events")
				await addDoc(eventRef, formDataCopy)
				setLoading(false)
				toast.success("Event Created Successfully")
				navigate("/events")
			} catch (error) {
				setLoading(false)
				setFormError("Failed to create event")
			}
		},
		[formData, navigate, user.uid, endTime, startDate, startTime]
	)

	return (
		<Container maxWidth="md" style={{ marginTop: "20px" }}>
			<Paper elevation={3} style={{ padding: "20px", width: "1100px" }}>
				<div className="create-form">
					<Typography
						variant="h4"
						align="center"
						gutterBottom
						sx={{ color: "black" }}
					>
						Create a new
						<Typography
							variant="span"
							gutterBottom
							sx={{ color: "#009432", ml: ".25em" }}
						>
							Event
						</Typography>
					</Typography>

					<form onSubmit={handleFormSubmit} disabled={loading}>
						<Grid container spacing={2}>
							{/* Form Section */}
							<Grid item xs={12} md={6}>
								<Stack spacing={2} direction="column" sx={{ marginBottom: 2 }}>
									<Typography variant="body1" paragraph>
										<strong>Event Title:</strong>
									</Typography>
									<TextField
										style={{ marginTop: "0px" }}
										size="medium"
										type="text"
										variant="outlined"
										color="secondary"
										onChange={onChange}
										id="title"
										value={title}
										fullWidth
										required
									/>
									<Typography variant="body1" paragraph>
										<strong>Event Details / Description:</strong>
									</Typography>
									<TextareaAutosize
										style={{ marginTop: "0px" }}
										color="secondary"
										onChange={onChange}
										value={details}
										id="details"
										fullWidth
										required
										minRows={4}
									/>
								</Stack>

								<AddressAutofill
									accessToken={MAPBOX_ACCESS_TOKEN}
									onRetrieve={handleAutofillRetrieve}
								>
									<Typography
										variant="body1"
										paragraph
										style={{ marginBottom: "0px" }}
									>
										<strong>Event location</strong>
										<Typography variant="body1">Address:</Typography>
									</Typography>
									<TextField
										size="medium"
										type="text"
										variant="outlined"
										color="secondary"
										onChange={onChange}
										value={streetAddress}
										id="streetAddress"
										autoComplete="address-line1"
										fullWidth
										required
									/>

									{/* Grid for three across  City, State, Zip */}
									<Grid
										container
										spacing={{ xs: 2, md: 3 }}
										columns={{ xs: 4, sm: 8, md: 12 }}
										style={{ marginTop: "-18px", marginBottom: "5px" }}
									>
										<Grid item xs={2} sm={4} md={4}>
											<Typography variant="body1">City:</Typography>
											<TextField
												size="medium"
												type="text"
												variant="outlined"
												color="secondary"
												onChange={onChange}
												value={city}
												id="city"
												autoComplete="address-level2"
												required
											/>
										</Grid>
										<Grid item xs={2} sm={4} md={4}>
											<Typography variant="body1">State / Region:</Typography>
											<TextField
												size="medium"
												type="text"
												variant="outlined"
												color="secondary"
												onChange={onChange}
												value={state}
												id="state"
												autoComplete="address-level1"
												required
											/>
										</Grid>
										<Grid item xs={2} sm={4} md={4}>
											<Typography variant="body1">Postal Code:</Typography>
											<TextField
												size="medium"
												type="text"
												variant="outlined"
												color="secondary"
												onChange={onChange}
												value={zip}
												id="zip"
												autoComplete="postal-code"
											/>
										</Grid>
									</Grid>
								</AddressAutofill>

								{/* Grid for three across  Date, Start Time & End Time   MUI - X*/}
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<Grid
										container
										spacing={{ xs: 2, md: 3 }}
										columns={{ xs: 4, sm: 8, md: 12 }}
									>
										<Grid item xs={2} sm={4} md={4}>
											<DemoItem label="Event Start Date">
												<DesktopDatePicker
													value={startDate}
													onChange={(newValue) => setStartDate(newValue)}
												/>
											</DemoItem>
										</Grid>
										<Grid item xs={2} sm={4} md={4}>
											<DemoItem label="Event Start Time">
												<MobileTimePicker
													value={startTime}
													onChange={(newValue) => setStartTime(newValue)}
												/>
											</DemoItem>
										</Grid>
										<Grid item xs={2} sm={4} md={4}>
											<DemoItem label="Event End Time">
												<MobileTimePicker
													value={endTime}
													onChange={(newValue) => setEndTime(newValue)}
												/>
											</DemoItem>
										</Grid>
									</Grid>
								</LocalizationProvider>

								<Grid container spacing={2} sx={{ mt: 0 }}>
									<Grid item xs={6} sm={6} md={6}>
										<Typography variant="body1" paragraph sx={{ mb: 0 }}>
											<strong>Event Category:</strong>
										</Typography>
										<Select
											onChange={(option) => handleCategoryChange(option)}
											options={categories}
											value={category}
											id="category"
										/>
									</Grid>
									<Grid item xs={6} sm={6} md={6}>
										<Typography variant="body1" paragraph sx={{ mb: 0 }}>
											<strong>Suggested Age Range:</strong>
										</Typography>
										<Select
											onChange={(option) => onSelectChange(option, "agerange")}
											options={ageranges}
											value={agerange}
											id="agerange"
											isClearable
										/>
									</Grid>
								</Grid>

								<Stack
									spacing={2}
									direction="column"
									sx={{ marginBottom: 4, pt: 2 }}
								>
									<Typography variant="body1" paragraph sx={{ mb: 0 }}>
										<strong>Event Venue Name: </strong>
										(Optional)
									</Typography>
									<TextField
										style={{ marginTop: "0px" }}
										size="medium"
										type="text"
										variant="outlined"
										color="secondary"
										onChange={onChange}
										value={venueName}
										id="venueName"
										fullWidth
									/>
									<Typography variant="body1" paragraph sx={{ mb: 0 }}>
										<strong>Event Website URL:</strong>
									</Typography>
									<TextField
										style={{ marginTop: "0px" }}
										size="medium"
										type="text"
										variant="outlined"
										color="secondary"
										onChange={onChange}
										value={imgUrl}
										id="imgUrl"
										fullWidth
										required
									/>
								</Stack>

								<Button color="primary" type="submit" variant="contained">
									Submit
								</Button>

								{loading && <Button disabled>Loading</Button>}
								{formError && <p className="error">{formError}</p>}
							</Grid>
							{/* Map Section */}
							<Grid item xs={12} md={6}>
								{!location && <Spinner message="Loading map..."></Spinner>}
								{location && (
									<>
										<Grid
											container
											alignItems="center"
											justifyContent="space-between"
											style={{ marginBottom: "10px" }}
										>
											<Grid item>
												<Typography variant="body1">
													<strong>Adjust Marker if Needed:</strong>
												</Typography>
											</Grid>
											<Grid item>
												<Button
													onClick={toggleMapStyle}
													variant="outlined"
													style={{ color: "black" }}
												>
													Switch to{" "}
													{mapStyle === "mapbox://styles/mapbox/streets-v11"
														? "Satellite"
														: "Street"}{" "}
													View
												</Button>
											</Grid>
										</Grid>

										<div
											ref={mapContainerRef}
											style={{
												height: "625px",
												borderRadius: "8px",
												border: "1px solid #ddd",
											}}
										/>
									</>
								)}
							</Grid>
						</Grid>
					</form>
				</div>
			</Paper>
		</Container>
	)
}
