// Stander React Hooks
import { useState } from "react"

// Firebase Hooks
import { getAuth, sendPasswordResetEmail } from "firebase/auth"

// Toast for messaging
import { toast } from "react-toastify"
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt"

// Material UI for Styling
import {
	Grid,
	Paper,
	Avatar,
	TextField,
	Button,
	Link,
	Typography,
} from "@mui/material"

function ForgotPass() {
	const [email, setEmail] = useState("")

	// MaterialUI constants
	const paperStyle = {
		padding: 20,
		height: "50vh",
		width: 280,
		margin: "20px auto",
	}
	const TextFieldStyle = {
		marginBottom: "1em",
		marginTop: "1em",
	}
	const avatarStyle = { backgroundColor: "#93cb52" }
	const btnstyle = { margin: "8px 0", color: "white", backgroundColor: "black" }

	// Handlers
	const onChange = (e) => {
		setEmail(e.target.value)
	}

	async function handleSubmit(e) {
		e.preventDefault()
		try {
			const auth = getAuth()
			await sendPasswordResetEmail(auth, email)
			toast.success("Email was sent")
		} catch (error) {
			toast.error("Could not send reset password")
		}
	}

	return (
		<Grid>
			<Paper elevation={10} style={paperStyle}>
				<Grid align="center">
					<Avatar style={avatarStyle}>
						<PsychologyAltIcon />
					</Avatar>
					<h2>Forgot Password ?</h2>
				</Grid>
				<TextField
					style={TextFieldStyle}
					label="Email"
					placeholder="Email"
					value={email}
					id="email"
					onChange={onChange}
					fullWidth
					required
				/>

				<Button
					variant="contained"
					style={(btnstyle, avatarStyle)}
					fullWidth
					onClick={handleSubmit}
				>
					Request Password Reset
				</Button>

				<Typography style={{ paddingTop: "1em" }}>
					<Link href="/login" style={{ paddingLeft: ".5em" }}>
						Back to Log In
					</Link>
				</Typography>
			</Paper>
		</Grid>
	)
}

export default ForgotPass
