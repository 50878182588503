import { createTheme } from "@mui/material/styles"

const theme = createTheme({
	palette: {
		primary: {
			main: "#93cb52",
		},
		lightGray: {
			main: "#686868",
		},
	},
})

export default theme
