// App.js

// Standard React Hooks
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom"

// Authorization hooks
import { useAuthContext } from "./hooks/useAuthContext"

// Toastify for error messaging
import "react-toastify/dist/ReactToastify.css"
import { ToastContainer } from "react-toastify"

// All the pages and components for our Routes

// Navigation Bar
import NavBar from "./components/NavBar"
// Our Pages
import About from "./pages/About/About"
// Event Crud
// import CreateEvent from "./pages/Events/CreateEvent"
import CreateEvent from "./pages/Events/CreateEvent"
import EditEvent from "./pages/Events/EditEvent"
import EventPage from "./pages/Events/EventPage"

// Main HomePage
import Home from "./pages/Home/Home"

// User Dashboard and management
import Dashboard from "./pages/User/Dashboard"
import ForgotPass from "./pages/User/ForgotPass"
import Login from "./pages/User/Login"
import SignUp from "./pages/User/SignUp"

function App() {
	const { user, authIsReady } = useAuthContext()

	return (
		<>
			{authIsReady && (
				<Router>
					<NavBar />
					<Routes>
						{/* Public Routes */}
						<Route path="/" element={<Home />} />
						<Route path="/events/:id" element={<EventPage />} />
						<Route path="/about" element={<About />} />

						{/* Need to be logged in to create and/or edit events  or access dashboard*/}
						<Route
							path="/events/create"
							element={user ? <CreateEvent /> : <Navigate to="/" />}
						/>

						<Route
							path="/events/edit/:id"
							element={user ? <EditEvent /> : <Navigate to="/" />}
						/>

						<Route
							path="/dashboard"
							element={user ? <Dashboard /> : <Navigate to="/" />}
						/>

						{/* Routes for User account mgmt. ( Signup, Login, Forgot Password) */}
						<Route
							path="/login"
							element={!user ? <Login /> : <Navigate to="/" />}
						/>
						<Route
							path="/signup"
							element={!user ? <SignUp /> : <Navigate to="/" />}
						/>
						<Route
							path="/forgotpass"
							element={!user ? <ForgotPass /> : <Navigate to="/" />}
						/>

						{/* Catch All */}
						<Route path="*" element={<Navigate to="/" />} />
					</Routes>
				</Router>
			)}

			<ToastContainer />
		</>
	)
}

export default App
