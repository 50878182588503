// Firebase Imports
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth"
import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore"
import { db } from "../firebase/config"

// Navigate/Redirect user to new page when completed
import { useNavigate } from "react-router-dom"

// Icons for Logging in with Social Media
// Google Icon
import { FcGoogle } from "react-icons/fc"

// Future Login with Apple & Maybe Facebook
// import { TfiApple } from "react-icons/tfi"
// import { FaFacebook } from "react-icons/fa"

// Toast for Messaging
import { toast } from "react-toastify"

// Material UI for Styling
import { Button } from "@mui/material"

// Update Global Context to allow other components to know if user is logged in.
import { useAuthContext } from "../hooks/useAuthContext"

export default function OAuth() {
	// AUTH CONTEXT for login
	const { dispatch } = useAuthContext()

	// Init navigate functionality
	const navigate = useNavigate()

	// CSS for Social Media Login Buttons FUTURE
	const socialBtnstyle = {
		margin: "8px 0",
		color: "black",
		backgroundColor: "white",
	}

	async function onGoogleClick() {
		try {
			const auth = getAuth()
			const provider = new GoogleAuthProvider()
			const result = await signInWithPopup(auth, provider)
			const user = result.user

			// check for the user

			const docRef = doc(db, "users", user.uid)
			const docSnap = await getDoc(docRef)

			if (!docSnap.exists()) {
				await setDoc(docRef, {
					name: user.displayName,
					email: user.email,
					timestamp: serverTimestamp(),
				})
			}

			// dispatch login action
			dispatch({ type: "LOGIN", payload: user })

			navigate("/dashboard")
		} catch (error) {
			toast.error("Could not authorize with Google")
		}
	}

	return (
		<>
			<Button
				variant="contained"
				style={socialBtnstyle}
				fullWidth
				onClick={onGoogleClick}
			>
				<FcGoogle style={{ marginRight: ".5em" }} />
				Continue with Google
			</Button>
		</>
	)
}
